.card
  display: flex
  flex-direction: column
  height: 100%
  padding: 2.5rem 2rem
  border: .2rem solid var(--color-accent)
  transition: all .2s
  @media (min-width: 769px)
    margin-right: .5rem
    &:hover
      box-shadow: .35rem 0 0 .05rem var(--color-accent)
      .image
        opacity: 0

.title
  text-transform: capitalize
  font: bold 2rem var(--main-font)
  color: var(--color-accent)

.description
  max-width: 95%
  margin: 1rem 0 2rem
  color: var(--color-white-700)

.about
  margin-top: auto
  margin-bottom: 2rem
  color: var(--color-white-700)

.aboutTitle
  color: var(--color-accent)

.list
  display: flex
  gap: 0rem .5rem
  flex-wrap: wrap

.listItem
  text-transform: capitalize
  &:not(:last-of-type)::after
    content: ","

.picture
  position: relative
  width: 100%
  margin-bottom: 2rem
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  @media (min-width: 576px)
    height: 24rem
  @media (max-width: 575px) and (min-width: 376px)
    height: 20rem
  @media (max-width: 375px)
    height: 15rem

.image
  width: 100%
  height: 100%
  object-fit: cover
  object-position: center
  border-radius: inherit
  transition: opacity .2s
  @media (max-width: 768px)
    opacity: 0

.bottom
  padding-top: 1rem
  border-top: .2rem solid var(--color-white-700)

.link
  margin-left: -.2rem
  &--live
    position: relative
    margin-left: 1.8rem
    &::before
      position: absolute
      content: ""
      width: .6rem
      height: .6rem
      left: -1.3rem
      top: .1rem
      bottom: 0
      margin: auto 0
      border-radius: 1rem
      background-color: var(--color-white-700)
