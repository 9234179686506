:root
  --main-font: "ua-brand", sans-serif
  --second-font: "Barcade-Brawl", monospace

  --color-accent: #b87eef
  --color-accent-300: #3d294f
  --color-black: #0a192f
  --color-white: #e0e0f8
  --color-white-700: #b1b4cc
  --color-white-500: #9a9db1
