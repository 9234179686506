.social
  @media (min-width: 1000px)
    position: fixed
    bottom: 5rem
    left: 0
  @media (max-width: 999px)
    display: flex
    align-items: center

.item
  @media (min-width: 1000px)
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 6rem
    height: 6rem
    &::before
      content: ""
      position: absolute
      display: block
      width: 2.3rem
      height: .1rem
      left: 0
      background-color: var(--color-white-700)
  @media (max-width: 999px)
    &:not(:last-of-type)
      margin-right: 1rem

.link
  display: block
  width: 2.5rem
  height: 2.5rem
  color: var(--color-white-700)
  transition: all .3s
  &:hover
    color: var(--color-white)
  &:focus-visible
    color: var(--color-white)
    box-shadow: 0 0 0 .1rem var(--color-white)
    &:hover
      background-color: var(--color-white)
      color: var(--color-black)
  @media (min-width: 1000px)
    margin-left: 3rem
    z-index: 1
    &:hover
      transform: scale(1.1)
