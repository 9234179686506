.skills
  @media (min-width: 1000px)
    margin-top: 8rem
    padding-top: 3rem
  @media (max-width: 999px)
    margin-top: 5rem
    padding-top: 1.5rem

.accordion
  margin-top: 5rem

.item
  $parent: &
  position: relative
  padding-bottom: 1rem
  color: var(--color-white)
  &::before
    position: absolute
    content: ""
    right: 0
    bottom: 0
    width: 100%
    height: .1rem
    background-color: var(--color-accent-300)
  &:not(:last-of-type)
    margin-bottom: 2rem
  span
    display: flex
    align-items: center
    justify-content: right
    color: var(--color-white)
    cursor: pointer
    &::before
      display: inline-block
      content: ""
      width: 2rem
      height: 2rem
      color: tomato
      background-repeat: no-repeat
      background-position: center
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23B87EEF' d='M9.75 3v6.75H3v4.5h6.75V21h4.5v-6.75H21v-4.5h-6.75V3h-4.5Z'/%3E%3C/svg%3E")
      transition: transform .2s
    &:focus-visible
      color: var(--color-accent)
      text-decoration: underline
    h3
      display: flex
      align-items: center
      margin-left: auto
      @media (min-width: 576px)
        font: bold 2.4rem var(--main-font)
      @media (max-width: 575px)
        font: bold 2rem var(--main-font)
    svg
      margin-left: 2.5rem

.list
  position: relative
  display: flex
  flex-direction: column
  margin: 3rem 2rem 2rem 0
  padding-right: 2.5rem
  text-align: right
  &::after
    position: absolute
    content: ""
    right: 0
    top: 0
    height: 100%
    width: .1rem
    background-color: var(--color-accent)

.content
  &:not(:last-of-type)
    margin-bottom: 2.2rem

.title
  font: 600 2rem var(--main-font)

.listNested
  display: flex
  justify-content: flex-end

.listNestedItem
  margin-top: .55rem
  font: normal 1.8rem var(--main-font)
  color: var(--color-white-700)
  &:not(:last-of-type)
    margin-right: .7rem
    &::after
      content: ", "

.empty
  margin-top: 5rem
  text-align: right
  font: normal 2rem var(--second-font)
  color: var(--color-white-700)
