.about
  padding-top: 3rem

.content
  display: flex
  justify-content: space-between
  margin-top: 3rem
  @media (max-width: 999px)
    flex-direction: column-reverse

.left
  color: var(--color-white-700)
  p:not(:last-of-type)
    margin-bottom: 2rem
  strong
    color: var(--color-accent)
  @media (min-width: 1000px)
    width: 54%
    .picture
      display: none
  @media (max-width: 999px) and (min-width: 576px)
    max-width: 95%
  @media (max-width: 575px)
    display: flex
    flex-direction: column
    align-items: center

.right
  @media (max-width: 999px)
    display: none

.picture
  @media (min-width: 1000px)
    width: 35rem
  @media (max-width: 999px) and (min-width: 576px)
    max-width: 13rem
    max-height: 13rem
    margin: .7rem 2rem 1rem 0
    float: left
  @media (max-width: 575px)
    width: 10rem
    height: 10rem
    margin-bottom: 2rem
