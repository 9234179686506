.container
  display: flex
  align-items: center
  gap: 10px
  
.btn
  display: flex
  align-items: center
  justify-content: center
  width: 30rem
  margin-top: 4rem
  padding: 1.5rem 3.5rem
  border: 0.2rem solid var(--color-accent)
  color: var(--color-accent)
  transition: all 0.3s
  cursor: pointer
  @media (min-width: 576px)
    font: 400 1.4rem/1.9rem var(--second-font)
  @media (max-width: 575px)
    font: 400 1rem/1.5rem var(--second-font)
  &:hover
    transform: translateY(-.15rem)
    box-shadow: .2rem .2rem 0 .01rem var(--color-accent)

.photo
  display: grid
  place-content: center
  height: 5.3rem
  margin-top: 4rem
  padding: 1.5rem
  border: 0.2rem solid var(--color-accent)
  color: var(--color-accent)
  transition: all 0.3s
  cursor: pointer
  @media (min-width: 576px)
    font: 400 1.4rem/1.9rem var(--second-font)
  @media (max-width: 575px)
    font: 400 1rem/1.5rem var(--second-font)
  &:hover
    transform: translateY(-.15rem)
    box-shadow: .2rem .2rem 0 .01rem var(--color-accent)

  &:not(.active)
    position: relative
    &::after
      content: ""
      position: absolute
      left: 5%
      top: 48%
      width: 90%
      height: .2rem
      background-color: var(--color-accent)
      transform: rotate(45deg)