.user
  display: flex
  align-items: center
  justify-content: space-between
  gap: 2rem

.left
  display: flex
  align-items: center
  gap: 1.5rem
  padding: .5rem
  margin: -.5rem
  transition: all .2s
  &:hover
    .login
      color: var(--color-white)
  &:focus-visible
    color: var(--color-white)
    box-shadow: 0 0 0 .2rem var(--color-accent)

.avatar
  width: 4rem
  height: 4rem
  border-radius: 50%

.login
  font: bold 1.8rem var(--main-font)
  color: var(--color-white-700)
  transition: color .2s

.date
  font: 400 1.8rem var(--main-font)
  color: var(--color-white-700)

.content
  margin-top: 6rem

.title
  text-transform: capitalize
  font: bold 2.2rem var(--second-font)
  color: var(--color-white)

.description
  max-width: 85%
  margin-top: 3rem

.duties
  margin-top: 2rem

.dutiesTitle
  margin-bottom: 1rem
  color: var(--color-accent)

.dutiesList
  margin-left: 2rem
  list-style: circle

.dutiesItem
  font: 400 1.8rem var(--main-font)
  color: var(--color-white)
  &:not(:last-of-type)
    margin-bottom: 1rem

.descriptionItem
  font: 400 1.8rem var(--main-font)
  color: var(--color-white)
  &:not(:last-of-type)
    margin-bottom: 2rem

.links
  margin-top: 4rem
  margin-left: -0.3rem

.linksTitle
  color: var(--color-accent)
  margin-bottom: 1rem

.linksBlock
  display: flex
  margin-left: -.3rem

.separator
  padding: 0 1rem
  font: normal 1.8rem/2.6rem var(--main-font)
  color: var(--color-white-700)

.about
  margin-top: 4rem
  color: var(--color-white-700)

.aboutTitle
  color: var(--color-accent)

.list
  display: flex
  gap: 0rem .5rem
  margin-top: .5rem
  flex-wrap: wrap

.listItem
  text-transform: capitalize
  &:not(:last-of-type)::after
    content: ","

.image
  height: 100%
  margin-top: 2rem
  object-fit: contain
  object-position: center
  box-shadow: 1rem 1rem 0 .05rem var(--color-accent)
  @media (min-width: 769px)
    width: calc(100% - 1.5rem)
  @media (max-width: 768px)
    width: calc(100% - 1rem)
