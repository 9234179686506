.container
  width: 100%

.repos
  display: grid
  gap: 3rem
  width: 100%
  margin-top: 2rem
  @media (min-width: 769px)
    grid-template-columns: repeat(2, 1fr)

.tags
  display: flex
  justify-content: flex-end
  flex-wrap: wrap
  gap: 1rem 1.5rem
  width: 100%
  margin-bottom: 2.5rem

.tag
  display: block
  padding: .7rem 1rem
  text-transform: capitalize
  border-style: solid
  border-width: .2rem
  color: var(--color-white-500)
  font: normal 1rem var(--second-font)
  transition: all .2s
  cursor: pointer
  &:hover
    border-color: var(--color-white)
    color: var(--color-white)
  &:focus-visible
    color: var(--color-accent)
  &.tagActive
    border-color: var(--color-accent)
    color: var(--color-accent)
    @media (min-width: 576px)
      &:hover
        border-color: tomato
        color: tomato
      &:focus-visible
        color: tomato
        border-color: tomato

.empty
  margin-top: 3rem
  text-align: center
  font: normal 2rem var(--second-font)
  color: var(--color-white-700)

.emptySubtitle
  margin-top: 2rem
  text-align: center
  font: normal 2rem var(--main-font)
  color: var(--color-white-700)
