.inner
  &:not(.carousel)
    @media (min-width: 1000px)
      margin-top: 8rem
      padding-top: 3rem
    @media (max-width: 999px)
      margin-top: 5rem
      padding-top: 1.5rem
    @media (min-width: 769px)
      display: grid
      grid-template-columns: auto 1fr
      align-items: center
      .title
        grid-area: 1 / 1 / 2 / 2
      .body
        grid-area: 2 / 1 / 3 / 3
      .control
        grid-area: 1 / 2 / 2 / 3
        margin-left: auto
    @media (max-width: 768px)
      display: flex
      flex-direction: column
      .control
        order: 1
        align-self: center
        margin-top: 2rem

.carousel
  display: flex
  flex-direction: column
  @media (min-width: 1000px)
    margin-top: 4rem
  @media (max-width: 999px)
    margin-top: 2rem
  .control
    order: 1
    align-self: center
    margin-top: 2rem

.title
  margin-top: 2rem
  font: bold 2.4rem var(--main-font)
  color: var(--color-white)

.body
  margin-top: 4rem

.control
  display: flex
  gap: 1rem
  @media (min-width: 769px)
    margin-right: 0.5rem
  @media (max-width: 768px)
    width: 100%


.arrow
  @media (min-width: 769px)
    padding: 1.5rem 3.5rem !important
  @media (max-width: 768px)
    width: 100%

.list
  display: grid
  gap: 3rem
  width: 100%
  margin-top: 2rem
  @media (min-width: 769px)
    grid-template-columns: repeat(2, 1fr)

.error
  margin: 4rem 0 2rem
  font: normal 3rem var(--main-font)
  color: var(--color-white)

.btn
  margin-top: 4rem

.skeleton
  position: relative
  height: 23rem
  margin: 1rem
  border: .1rem solid var(--color-accent)
  box-shadow: .1rem .1rem 0 .1rem var(--color-accent)
  background-color: #0c1e38
  overflow: hidden
  &::after
    content: ""
    position: absolute
    width: 100%
    height: 100%
    background-image: linear-gradient(90deg, #0c1e38 0, var(--color-accent-300) 14rem, #0c1e38 18rem)
    background-size: cover
    animation: shine-lines 1.5s infinite linear
    @keyframes shine-lines
      0%
        left: -50%
      100%
        left: 100%
