.container
  width: 100%

.projects
  margin-top: 2rem

.empty
  margin-top: 3rem
  text-align: center
  font: normal 2rem var(--second-font)
  color: var(--color-white-700)

.emptySubtitle
  margin-top: 2rem
  text-align: center
  font: normal 2rem var(--main-font)
  color: var(--color-white-700)
