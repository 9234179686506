.card
  display: flex
  flex-direction: column
  border: .2rem solid var(--color-accent)
  padding: 2.5rem 2rem
  transition: all .2s
  &:hover
    transform: translateY(-.15rem)
    box-shadow: .35rem .35rem 0 .05rem var(--color-accent)
  @media (min-width: 769px)
    height: 23rem

.title
  text-transform: capitalize
  font: bold 2rem var(--main-font)
  color: var(--color-accent)

.description
  margin: 1rem 0 2rem
  color: var(--color-white-700)
  @media (min-width: 769px)
    word-wrap: anywhere
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden

.bottom
  margin-top: auto

.link
  margin-left: -.2rem
  &--live
    position: relative
    margin-left: 1.8rem
    &::before
      position: absolute
      content: ""
      width: .6rem
      height: .6rem
      left: -1.3rem
      top: .1rem
      bottom: 0
      margin: auto 0
      border-radius: 1rem
      background-color: var(--color-white-700)

.tags
  display: flex
  flex-wrap: wrap
  gap: 1rem
  padding-top: 1.5rem
  li
    padding: .7rem
    text-transform: uppercase
    font: normal .8rem var(--second-font)
    border: .15rem solid var(--color-white-500)
    color: var(--color-white-500)
