.footer
  margin-top: auto
  padding-bottom: 3rem

.inner
  display: flex
  flex-direction: column
  align-items: center

.author
  text-align: center
  color: var(--color-white-700) !important
  &:hover
    color: var(--color-white) !important
  @media (min-width: 1000px)
    margin-bottom: .5rem
  @media (min-width: 375px)
    br
      display: none

.social
  @media (max-width: 999px)
    margin: 1rem 0
